import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './index.css';
import App from './App';
import authentication from './utils/authentication';
import Config from './utils/config';
import { initPermissions } from './utils/permissions';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { loginRequest, msalConfig } from './utils/authConfig';
import { CookiesProvider } from 'react-cookie';
const root = ReactDOM.createRoot(document.getElementById('root'));
const config = new Config();
const msalInstance = new PublicClientApplication(msalConfig);

authentication(config, msalInstance, (error) => {
  if (error) {
    console.error('session error: ', error);
  }
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  // Set active account on page load
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
      config.set('U42.USER_NAME', msalInstance.username);
    } else {
      msalInstance
        .initialize()
        .then((res) => {
          // handle auth redirect/do all initial setup for msal
          msalInstance
            .handleRedirectPromise()
            .then((authResult) => {
              // redirect anonymous user to login page
              msalInstance.loginRedirect();
              const accounts = msalInstance.getAllAccounts();
              msalInstance.setActiveAccount(accounts[0]);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  } catch (err) {
    msalInstance
      .initialize()
      .then((res) => {
        // handle auth redirect/do all initial setup for msal
        msalInstance
          .handleRedirectPromise()
          .then((authResult) => {
            // redirect anonymous user to login page
            msalInstance.loginRedirect();
            const accounts = msalInstance.getAllAccounts();
            msalInstance.setActiveAccount(accounts[0]);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  msalInstance.addEventCallback(
    (event) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    },
    (error) => {
      console.log('error', error);
    }
  );

  msalInstance
    .initialize()
    .then((res) => {
      let authorized = false;
      msalInstance
        .acquireTokenSilent({
          ...loginRequest,
          account: msalInstance.getActiveAccount(),
        })
        .then((accessTokenResponse) => {
          // Acquire token silent success
          //Save token in U42 config
          const bearer = `Bearer ${accessTokenResponse.accessToken}`;
          config.set('U42.TOKEN', bearer);
          config.set('U42.TOKEN_EXPIRES_ON', accessTokenResponse.expiresOn);
          axios.defaults.headers.common = { Authorization: config.get('U42.TOKEN') };
          axios.defaults.maxContentLength = Infinity;
          axios
            .get('/api/authorization/')
            .then((response) => {
              initPermissions(response.data.permissions);
              authorized = response.data.permissions.length > 1 && response.data.authorized;
              config.set('U42.AUTHORIZED', authorized);
              // We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.

              root.render(
                <MsalProvider instance={msalInstance}>
                  <CookiesProvider>
                    <App authorized={authorized} instance={msalInstance} />
                  </CookiesProvider>
                </MsalProvider>
              );
            })
            .catch((error) => {
              console.log('Error: ', error.message, ' ... ', error.response && error.response.data);
              console.log('Response: ', error.response);
              let errMsg = error.message;
              errMsg += error.response && error.response.data ? ' ... ' + error.response.data : '';
              root.render(
                <MsalProvider instance={msalInstance}>
                  <App authorized={authorized} instance={msalInstance} errMsg={errMsg} />
                </MsalProvider>
              );
              //Refresh the browser
              if (error.response.status === 400) {
                window.location.reload();
              }
            });
        })
        .catch((err) => {
          //Acquire token silent failure
          console.log('Acquire token silent failure :' + err);
          console.log(JSON.stringify(err.errorCode));
          let errMsg = err.message;
          let invalidToken = false;
          if (err && (err.errorCode === 'invalid_grant' || 'interaction_required')) {
            invalidToken = true;
          }
          errMsg += err.response && err.response.data ? ' ... ' + err.response.data : '';
          root.render(
            <MsalProvider instance={msalInstance}>
              <App
                authorized={authorized}
                instance={msalInstance}
                invalidToken={invalidToken}
                errMsg={errMsg}
              />
            </MsalProvider>          
          );
        });
    })
    .catch((err) => {
      console.log(err);
    });
});
